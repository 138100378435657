import React from 'react';
import { Layout, Col, Card } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import compliant from '../assets/compliant.png';
import highVisibility from '../assets/high-visibility.png';
import manager from '../assets/manager.png';
import imgBinfo from '../assets/binfo-img.png';
import automationTags from '../assets/automationtags.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function About() {
  return (
    <Content className={styles.basicInfo}>
      <Row style={{ margin: '50px auto' }}>
        <h1 style={{ width: '100%', textAlign: 'center' }}>
          <span>It's time for Tagbot's</span>
          <br />
          take on tagging.
        </h1>
        <div className={styles.frDiv}>
          <p className={styles.basicInfoTxtc}>
            At Tagbot, we’ve found there is often a general lack of knowledge
            around tagging and how it can be used to effectively manage
            resources on AWS.
          </p>
        </div>
      </Row>

      <div className={styles.midBgRevert}>
        <Row
          gutter={24}
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginBottom: '50px',
          }}
          className={styles.containerImgTypes}
        >
          <Col span={12} style={{ zIndex: '100', marginTop: '150px' }}>
            <p className={styles.typesInfoTxt3}>
              Companies deploying resources on AWS should implement
              best-practices in their tagging efforts, and a simple tool that
              could provide them with right information, insights, and
              guidelines would be very useful... Tagbot is the tool we’ve built
              to do just that...
            </p>
          </Col>
          <Col span={12} style={{ paddingRight: '0px' }}>
            <img
              src={imgBinfo}
              style={{ transform: 'translate(100px, -20%)', height: '750px' }}
              className={styles.typesImg}
            />
          </Col>
        </Row>
      </div>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <h1 style={{ paddingLeft: '25px' }}>
            <span>Tagging resources on AWS.</span>
            <br />
            Not just an afterthought.
          </h1>
        </Col>
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card bordered={false} className={styles.reinsuranceCardFour}>
            A strategic approach to tagging and rigorous enforcement of tagging
            policies provides AWS users with the necessary visibility to
            effectively identify, track and manage their resources on AWS. This
            in turn helps optimize expenses while improving the ROI of their
            cloud deployments, and provides an effective means of allocating
            expenses to the correct cost centers. Effective tagging is thus
            indispensable for companies interested in optimizing and allocating
            their cloud costs on AWS.{' '}
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: '80px' }}>
        <h1 style={{ width: '100%', textAlign: 'center' }}>
          <span>Why use tags</span> on AWS?
        </h1>
        <p className={styles.basicInfoTxtc}>
          Keeping track of cloud resources is often an afterthought during
          deployment, and especially as cloud environments get larger teams
          often struggle with the ongoing management, accounting and reporting
          of their infrastructure. Cross-business unit or multi-team projects,
          the use of multiple regions and platforms and other complexity factors
          can all further exacerbate the issue.. As a result, the larger a cloud
          deployment gets, the harder it becomes for teams to effectively
          monitor their resources. Effective tagging is the tool to address the
          problem...{' '}
        </p>
      </Row>

      <Row
        gutter={16}
        className={styles.basicInfoCardRow}
        style={{ margin: '50px auto' }}
      >
        <Col
          span={8}
          style={{ maxWidth: 'none' }}
          className={styles.responsiveCardCenter}
        >
          <Card
            bordered={false}
            cover={
              <img
                className={styles.imgCardBinfo}
                src={compliant}
                alt={'compliant'}
              />
            }
            className={styles.reinsuranceCardOne}
          >
            Tags allow companies to manage their AWS cloud deployments at scale
            by providing the necessary visibility to effectively oversee their
            deployed resources.{' '}
          </Card>
        </Col>
        <Col
          span={8}
          style={{ maxWidth: 'none' }}
          className={styles.responsiveCardCenter}
        >
          <Card
            bordered={false}
            cover={
              <img
                className={styles.imgCardBinfo}
                src={highVisibility}
                alt={'high visibility'}
              />
            }
            className={styles.reinsuranceCardOne}
          >
            Implemented correctly, tags provide visibility of the precise
            resources at play at any given time, who are using them, and the
            business purpose for which these resources were created.{' '}
          </Card>
        </Col>
        <Col
          span={8}
          style={{ maxWidth: 'none' }}
          className={styles.responsiveCardCenter}
        >
          <Card
            bordered={false}
            cover={
              <img
                className={styles.imgCardBinfo}
                src={manager}
                alt={'manage'}
              />
            }
            className={styles.reinsuranceCardOne}
          >
            The more high-quality tags you assign to your resources, the easier
            it becomes to manage them.{' '}
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
